.header {
  box-shadow: none !important;
  border: 0 !important;
  border-bottom: 1px solid $primary_grayLight !important;
  &__logo {
    width: 4.8rem;
    height: 3rem;
    padding: 1rem;
    border-right: 1px solid $primary_grayLight;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__spacer {
    width: calc(100% - 6rem);
    padding: 0 1.5rem;
  }
}

.header.MuiPaper-root div.MuiToolbar-root button[title='Close menu'] {
  display: none !important;
}

.menu {
  position: fixed;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 6.5rem;
  top: 0;
  left: 0;
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0.1rem;
    height: 120%;
    background-color: $primary_grayLight;
    top: -20%;
    right: 0;
  }
  &__top {
    width: 100%;
    padding: 1rem 0;

    .logo {
      width: 4.5rem;
      height: 3rem;
      margin: 0 auto;
      margin-bottom: 2rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid $primary_grayLight;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__link {
    flex-direction: column;
    svg {
      color: $primary_grayDark_1;
      font-size: 2.1rem !important;
      width: 2.1rem !important;
      height: 2.1rem !important;
    }

    .menu-name {
      // position: absolute;
      // top: 3.5rem;
      // background-color: #000;
      // border-radius: 0.5rem;
      // color: #fff;
      font-size: 1.2rem;
      padding-top: 0.5rem;
      // display: none;
      //transition: all 0.5s ease-out;
    }

    // &:hover{
    //     .menu-name {
    //         display: block;
    //     }
    // }

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      svg {
        color: $primary_blueDark_1;
      }
    }
  }

  &__bottom {
    padding: 1rem 0;
    width: 100%;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: $primary_font;
  font-size: 1.2rem;
}

.list-page {
  min-width: 100%;
  .MuiToolbar-root {
    flex-direction: column;
    // padding-right: 10px;
    // padding-left: 10px !important;
    // padding:10px !important;
    .MuiToolbar-regular {
      order: 1;
      flex-direction: row;
      justify-content: flex-end;
      width: 100%;
      .MuiButton-textPrimary,
      .MuiButton-sizeSmall {
        // margin-left: 0.8rem;
        padding: 1rem 1.6rem;
        font-size: 1rem;
        line-height: 1.8rem;
        font-weight: 600;
        text-transform: capitalize;
        border-radius: 0.4rem;
        border: 1px solid;
        box-shadow: none !important;
        max-height: 4rem;
        min-width: auto;
        justify-content: center;
        background-color: #f2f2f2;
        color: '#616569';
        border-color: #c2c9d1;
        font-family: 'Inter', sans-serif !important;
        .MuiSvgIcon-root {
          font-size: 1.4rem !important;
        }
        &:hover,
        &:focus {
          background-color: '#F9FAFA !important';
          border-color: '#92979D !important';
        }
        &:active {
          background-color: '#F0F2F3 !important';
          border-color: '#92979D !important';
        }
        &.Mui-disabled {
          background-color: '#fff !important';
          border-color: '#E1E4E8 !important';
          color: '#C2C9D1 !important';
        }
      }
    }
  }
  .MuiCard-root {
    box-shadow: none;
    width: 100%;
  }
}

.filter-form {
  order: 2;
  margin-bottom: 4rem;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.4rem #f0f2f3;
  background-color: #f0f2f3;
}

*::-webkit-scrollbar {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.5rem;
  background-color: #f0f2f3;
}

*::-webkit-scrollbar-thumb {
  background-color: #c2c9d1;
}

.MuiTablePagination-root {
  .MuiToolbar-root {
    flex-direction: row;
    .MuiTypography-root {
      font-size: 1.2rem !important;
    }
    .MuiTablePagination-input {
      margin: 0 1.5rem !important;
      .MuiSelect-select {
        font-size: 1.2rem;
        font-weight: 600;
        border: 1px solid #000;
        border-radius: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 0;
      }
      .MuiSelect-icon {
        top: calc(50% - 8px);
      }
    }
    .MuiButton-textSizeSmall {
      padding: 0.5rem 1rem;
      font-size: 1.2rem;
      min-width: auto;
    }
  }
}

main.RaLayout-contentWithSidebar-3 {
  width: 100%;
  overflow: auto;
}

.MuiDrawer-docked {
  width: 6.5rem;
}

.layout {
  min-width: 100% !important;
}

#main-content {
  width: calc(100% - 310px);
  display: block;
  padding: 0 !important;
  // height: 100vh;
  // overflow: hidden;
}

.tabWborder {
  border-bottom: 1px solid #c2c9d1;
  padding: 0 2rem;
}

.MuiTabs-root {
  min-height: auto !important;
}

.MuiTreeView-root {
  padding: 0 1rem !important;
  height: 50vh;
  overflow-y: auto;
  .MuiTreeItem-root {
    margin-bottom: 0.7rem;
    .MuiSvgIcon-root {
      margin-left: 1.8rem !important;
      font-size: 2.5rem !important;
      background-color: transparent !important;
    }
  }
  .MuiTreeItem-content {
    padding: 0;
    .MuiTreeItem-label {
      font-size: 1.3rem;
      color: #333333;
    }
    .MuiTreeItem-root {
      .MuiTreeItem-label {
        color: #484964;
      }
    }
  }
}

.mapSec {
  &_header {
    background-color: #616569;
    padding: 1rem 2rem;
    .MuiTypography-root {
      color: #fff;
    }
  }
  &_dateTeam {
    .MuiFormControl-root {
      margin: 1rem 0;
      .MuiFormLabel-root {
        font-size: 1.4rem;
        font-weight: 500;
      }
      .MuiInputBase-root {
        &::before {
          display: none;
        }
        .MuiInputBase-input {
          font-size: 1.5rem;
        }
      }
    }
  }
  &_accordion {
    .MuiAccordionSummary-root {
      .MuiAccordionSummary-content {
        margin: 0 !important;
        .MuiTypography-root {
          font-size: 1.6rem;
        }
      }
      .MuiAccordionSummary-expandIcon {
        .MuiSvgIcon-root {
          font-size: 2rem !important;
        }
      }
    }
  }
}

.listStyle {
  .MuiListItem-root {
    box-shadow: 0 1rem 2rem rgb(0 0 0 / 30%);
    border-radius: 1rem;
    padding: 2rem;
    align-items: flex-start;
  }
  .status {
    font-size: 2rem;
    position: absolute;
    display: block;
    right: 1rem;
    top: 1rem;
  }
}

.showController {
  .MuiPaper-root {
    box-shadow: none;
  }
}

.createNode {
  position: absolute;
  display: block;
  background: #fff;
  padding: 1rem;
  max-width: 26rem;
  margin-top: 0.5rem;
  z-index: 1;
  li {
    list-style: none;
  }
}

.editable-columns {
  position: absolute;
  top: -45px;
}

.scrollable-force-tabpanel {
  overflow-y: auto;
  height: 100%;
  .MuiTreeView-root {
    &::-webkit-scrollbar {
      width: 0px !important;
    }
    &::-webkit-scrollbar-thumb {
      background: none !important;
    }
  }
  &::-webkit-scrollbar {
    width: 0px !important;
  }
  &::-webkit-scrollbar-thumb {
    background: none !important;
  }
}
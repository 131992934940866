.p-15{
    padding: 15px;
}

.pr-10{
    padding-right: 10px;
}

.pl-10{
    padding-left:10px
}

.pr-100{
    padding-right: 100px;
}

.pr-10{
    padding-right: 10px;
}

.m-0{
    margin: 0px;
}

.mb-0{
    margin-bottom: 0px;
}

.w-80p{
    width: 80%;
}


.bg-blue{   
    background-color: #f6f7fe; 
    border: 1px dotted #9eb2fc;
}



.z-editor-right-list {
  width: 100%;
  height: 100%;
  max-height: 80vh;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  list-style: none;
  border-right: 1px solid #ccc;
  background-color: #fff;
}

.z-output {
  font-family: Inter !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  letter-spacing: 0px !important;
  text-align: left !important;
  color: #00000080 !important;
}

.z-outer-grid {
  position: relative;
  height: 700px;
  top: 0px !important;
}

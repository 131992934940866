.pushNotification__container{
    /* display: flex;
    justify-content: center; */
    margin-top: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    width: 35vw;
    background-color: white;
    margin-left: 30vw;
    margin-top: 30vh;
    padding: 8px;
    border-radius: 4px;
    
}
 
.pushNotification__input {
    border: 2px solid #eaeaef;
    padding: 8px;
    border-radius: 8px;
    color: black;
    width: 100%;
    font-weight: 400;
}

.pushNotification__heading {
    font-weight: 600;
    margin-bottom: 14px;
}


.flex__div {
    display: flex;
    gap: 8px;
}

.pushNotification__filed {
    margin-bottom: 1rem;
    display:flex;
    width: 50%;
    /* justify-content: center; */
}

.pushNotification__icon {
    display: flex;
    align-items: center;
    margin-left: 1rem;
}
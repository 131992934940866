@import url('https://fonts.googleapis.com/css2?family=Vujahday+Script&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 12px;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-size: 1.2rem;
  font-family: 'Inter', sans-serif !important;
}

@import 'scss/style.scss';

.leaflet-container {
  height: 100%;
  width: 100%;
  margin: 0;
}
.svg_icons_s {
  transform: scale(0.9);
  font-size: 0.9rem !important;
}

.svg_icons_m {
  transform: scale(1.1);
  font-size: 1rem !important;
}

.svg_icons_l {
  transform: scale(1.4);
}

.svg_icons_xl {
  transform: scale(1.8);
}

.pointer {
  cursor: pointer !important;
}

.m-0{
  margin: 0px;
}

.m-1 {
  margin: 0.5rem;
}

.m-2 {
  margin: 1rem;
}

.ml-2 {
  margin-left: 1rem !important;
}

.mr-2 {
  margin-right: 1rem !important;
}

.mt-1 {
  margin-top: 0.5rem !important;
}

.mt-2 {
  margin-top: 1rem !important;
}

.mt-3 {
  margin-top: 1.5rem !important;
}

.mt-4 {
  margin-top: 2.5rem !important;
}

.mb-1 {
  margin-bottom: 0.5rem !important;
}

.mb-2 {
  margin-bottom: 1rem !important;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.mr-32{
  margin-right: 32px !important;
}

.p-1 {
  padding: 0.5rem;
}

.p-2 {
  padding: 1rem;
}

.p-5 {
  padding: 1.5rem !important;
}

.showTaskParentDiv .tool-bar {
  .MuiButtonBase-root,
  .MuiButton-textSizeSmall {
    font-size: 1rem !important;
  }
  .RaUserMenu-user {
    font-size: 1.2rem !important;
  }
  display: flex;
  justify-content: space-between  !important;
  // align-items: center !important;
  overflow: auto;
  gap:10px;
  border-bottom: 1px solid #e1e4e8 !important;
  margin-bottom: 20px !important;
  padding: 10px 32px !important;
  background: #FFF;
  @media screen and (max-width: 800px) and (min-width: 501px) {
    justify-content: space-around  !important;
  }

  @media screen and (max-width: 599.95px) {
    padding-left: 0 !important;
  }
} 

.showTaskParentDiv .mobile {
  .MuiButtonBase-root,
  .MuiButton-textSizeSmall {
    font-size: 1rem !important;
  }
  .RaUserMenu-user {
    font-size: 1.2rem !important;
  }
  display: flex;
  justify-content: space-between  !important;
  // align-items: center !important;
  overflow: auto;
  gap:10px;
  border-bottom: 1px solid #e1e4e8 !important;
  margin-bottom: 20px !important;
  padding: 10px 32px !important;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #C2C9D1;
  @media screen and (max-width: 790px) and (min-width: 501px) {
    // justify-content: space-around  !important;
  }

  @media screen and (max-width: 599.95px) {
    padding-left: 0 !important;
  }
} 

.dialog__title {
  font-weight: 600 !important;
  font-size: 18px !important;
  color: #101828 !important;
}

.dialog__content {
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #475467 !important;
}


.setting-bar {
  // margin-bottom: 24px;
  padding-bottom: 24px !important;
    border-bottom: 1px solid #EAECF0 !important;
    margin-bottom: 24px;
}

.tool-bar {
  .MuiButtonBase-root,
  .MuiButton-textSizeSmall {
    font-size: 1rem !important;
  }
  .RaUserMenu-user {
    font-size: 1.2rem !important;
  }
  display: flex;
  justify-content: space-between  !important;
  align-items: center !important;
  overflow: auto;
  gap:10px;
  border-bottom: 1px solid #e1e4e8 !important;
  margin-bottom: 20px !important;
  padding: 24px !important;
  // background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #C2C9D1;
  @media screen and (max-width: 800px) and (min-width: 501px) {
    justify-content: space-around  !important;
  }

  @media screen and (max-width: 599.95px) {
    padding-left: 0 !important;
  }

}

.MuiIconButton-root {
  // border: 1px solid #D0D5DD !important;
    border-radius: 4px !important;
    padding: 4px !important;
}

.contentWrapper {
  padding: 0 16px !important;
  // height: 84vh !important;
  // overflow: auto;

  @media screen and (max-width: 800px) {
    padding: 20px !important;
  }

}

.contentWrapper-edit {
  padding: 32px !important;
  height: 100vh !important;
  overflow: auto;

  @media screen and (max-width: 800px) {
    padding: 20px !important;
  }

}

.contentWrapper-show {
  padding: 24px 32px !important;
  height: 75vh !important;
  overflow: auto;
  display: grid;
  grid-template-columns: 65% 35%;
  gap:20px;

  @media screen and (max-width: 800px) {
    padding: 20px !important;
    grid-template-columns: 100%;
  }

}

.contentWrapper-show-entity-new {
  overflow: auto;
  display: grid;
  grid-template-columns: 65% 35%;
  @media screen and (max-width: 800px) {
    padding: 20px !important;
    grid-template-columns: 100%;
  }

}

.contentWrapperShowMode-entity {
  // padding: 0 32px !important;
  height: calc(100vh - 56px) !important;
  overflow: auto;
  @media screen and (max-width: 800px) {
    padding: 20px !important;
  }
}

.contentWrapperShowMode {
  padding: 0 32px !important;
  height: 81vh !important;
  overflow: auto;

  @media screen and (max-width: 800px) {
    padding: 20px !important;
  }
}

.workflow-toolbar {
  display: flex;
  justify-content: space-between  !important;
}

.app-bar {
  .MuiButtonBase-root,
  .MuiButton-textSizeSmall,
  .RaUserMenu-user {
    font-size: 1.24rem;
  }
}

.MuiStepLabel-root {
  align-items: flex-start !important;
}

.MuiStepConnector-root {
  margin-left: 25px !important;
}

.RaSidebar-drawerPaper-40 {
  width: 75px !important;
}

.app-bar-header {
  font-size: 14px;
}

.special-word {
  color: blue !important;
}

.special-word-delete {
  color: red !important;
}

.special-word-highlight {
  color: green;
  font-weight: 500;
}

.block-word {
  background: #3e66fb;
    color: white;
    padding: 4px;
    border-radius: 4px;
}


.resize {
  resize: none;
}


.border-bottom{
  border-bottom: 1px solid #C2C9D1;
}


.w-80p{
  width: 80%;
}

.map-marker {
  width: 20px !important;
}
.Toastify__toast {
  padding : 16px !important;
  border-radius: 8px !important;
  box-shadow: 0px 3px 6px rgba(140, 152, 164, 0.25);
  border-radius: 8px;
}

.Toastify__toast--error  {
  border-left: 5px solid #F03738  !important;
  
}

.Toastify__toast--info  {
  border-left: 5px solid #3E66FB  !important;
  
}

.Toastify__toast--warning  {
  border-left: 5px solid #E18344  !important;
  
}

.Toastify__toast--success  {
  border-left: 5px solid #3CC13B  !important;
  
}


.Toastify__progress-bar--error {
  background-color: #F03738 !important;
}
.Toastify__progress-bar--success {
  background-color: #3CC13B !important;
}
.Toastify__progress-bar--warning {
  background-color: #E18344 !important;
}

.Toastify__progress-bar--info {
  background-color: #3E66FB !important;
}

.MuiDialog-paperFullWidth {
  max-width: 100% !important;
}

.MuiStepConnector-line {
  margin-left: -15px !important;
}




.numberedTaskMarker {
  text-align: center;
  font-weight: normal;
  color: #fff;
  /* background-color: ${myCustomColour}; */
  width: 1.3rem;
  height: 1.3rem;
  display: block;
  left: -0.6rem;
  top: 0.05rem;
  position: relative;
  border-radius: 3rem 3rem 0rem;
  transform: rotate(45deg);
  border: 1px solid #ffffff;
  background-color: green !important;
}

.numberedSelectedTaskMarker {
  text-align: center;
  font-weight: normal;
  color: #fff;
  /* background-color: ${myCustomColour}; */
  width: 1.6rem;
  height: 1.6rem;
  display: block;
  left: -0.8rem;
  top: -0.3rem;
  position: relative;
  border-radius: 3rem 3rem 0rem;
  transform: rotate(45deg);
  border: 1px solid #000;
  background-color: blue;
}

.numberedUserMarker {
  text-align: center;
  /* background-color: ${myCustomColour}; */
  width: 0.8rem;
  height: 0.8rem;
  display: block;
  left: -0.5rem;
  top: 1.1rem;
  position: relative;
  border-radius: 3rem 3rem 3rem;
  transform: rotate(0deg) !important;
  border: 1px solid #ffffff;
  background-color: red !important;
}

.textMarker {
  transform: rotate(-45deg);
  text-align: center;
}

#searchUser.MuiOutlinedInput-input {
  padding: 4px !important;
  max-width: 150px !important;
  /* border: 1px solid #000; */
  border-radius: 4px;
}

.containerClass {
  background-color: whitesmoke;
  border:solid 1px grey;
  font-size : 10px; 
}
.inputClass {
  background-color: white;
  padding: 5px;
  color: black;
  width: 200px;
  height: 30px;
}
.resetButtonClass {
  background-color: lightpink;
  width: 20px;
  height: 30px;
}
.userPositionsTableClass {
  border: 1.5px solid grey;
  padding: 8px;
}
.divClass {
  height: 300px;
  overflow: scroll;
}
.content {
  /* margin-left: 5%; */
  padding-left: 1rem;
}


.primary {
  background-color: #3e66fb;
  padding:10px 16px;
  width:6rem;
}

.secondary {
  background-color: #f2f2f2;
  border: 1px solid #C2C9D1;
  color:#616569 !important;

}

.button {
  padding: 8px 16px;
  border-radius: 4px;
  color: white;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display:flex;
}




.content__paramsTableRow:last-child {
  /* border-right: 1px solid #eaeaea; */
  /* border-bottom: 1px solid #eaeaea; */
  border-right: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}
.content__tableInput {
  /* border-radius: 0px; */
  /* border: 1px solid #eaeaea; */
  font-size: 12px;
  color: #555555;
  padding: 5px;
  width: 95%;
  height:100%
}

.map-container {
    position: relative;
    height : 100%;
    width: 100%;
}
  
.map {
    z-index: 0;
    height : 700px;
    width: 100%;
}
  
.filter-overlay {
    position: absolute;
    top: 60px;
    right: 40px;
    z-index: 1;
    background-color: white;
    padding: 3px;
    border: 1px solid #ccc;
    border-radius: 2px;
}

.refresh-overlay {
    position: absolute;
    top: 70px;
    right: 250px;
    z-index: 1;
    background-color: white;
    padding: 1px;
    border: 1px solid #ccc;
    border-radius: 2px;
}

.userPositionsTableClass {
    border: 1.5px solid grey;
    padding: 8px;
  }
  .divClass {
    height: 300px;
    width: 500px;
    overflow: scroll;
  }
html {
  --filterScrollbarBG: transparent;
  --filterThumbBG: #a6a6a6;
}

body.modal-open::before {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #000;
  top: 0;
  left: 0;
  z-index: 9;
  opacity: 0.5;
}

.textarea {
  width: 100%;
  border: 1px solid #c2c9d1 !important;
  border-radius: 4px;
  padding: 4px 6px;
  height: 26px;
  font-size: 12px;
}

.cust-card-1 {
  padding: 1% 0% 1% 0%;
  background-color: transparent;
}
.mt-2 {
  margin-top: 2%;
}

.MuiDrawer-paper {
  background-color: #ffffff !important;
  color: rgba(0, 0, 0, 0.8) !important;
  border: 1px solid #eaeaef !important;
  border-bottom: 0px !important;
}

/* Done for sidebar menu to show up all the time */
.MuiDrawer-docked {
  flex: 0 0 auto;
  width: 64px !important;
  height: inherit !important;
  /* display: none !important; */
}

.labelTextForAccountCreate {
  color: rgba(0, 0, 0, 0.8) !important;
  font-family: Inter, "sans-serif" !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}

.helperTextForAccountCreate {
  font-size: smaller;
  color: #757575;
  font-weight: 500;
  margin-top: 4px;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
  /* border: 2px solid #eaeaef !important; */
}

@media (min-width: 600px) {
  header.MuiPaper-root div.MuiToolbar-root button[title="Close menu"] {
    display: none !important;
  }
  header.MuiPaper-root div.MuiToolbar-root button[title="Open menu"] {
    display: none !important;
  }
}

/* This is for the "Export" button at the top of the table */
button[aria-label="Export"] {
  color: rgba(0, 0, 0, 0.6);
}

/* This is to remove the black background when scrolling */

main.RaLayout-contentWithSidebar-3 {
  /* margin-top: 1rem; */
  background-color: #ffffff !important;
}

/* This is to make height of sidebar full screen */
.RaSidebar-root-26 {
  height: inherit !important;
  width: 65px !important;
}

.RaSidebar-drawerPaper-54 {
  width: 65px !important;
}

.updateDataFieldImg {
  /* position: absolute; */
    z-index: 0;
    /* right: 33px; */
    border-radius: 8px;
    border: 1px solid var(--Gray-300, #D0D5DD);
    background: var(--Base-White, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    display: flex;
    padding: 2px 8px;
    align-items: center;
    border-radius: 4px;
    border: 1.5px solid var(--Primary-600, #3C69E7);
    color: var(--Primary-700, #3054B9);
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    cursor: pointer;
}

.updateDataFieldImg2 {
  padding: 0px;
  margin: 0px;
  height: 31px;
}

.MuiCircularProgress-root {
  height: 15px !important;
  width: 15px !important;
}

.RaMenu-open-42 {
  width: 67px !important;
}

.RaSidebar-drawerPaper-40 {
  width: 100% !important;
}

/* #main-content {
  background-color: #fff;
  padding:0px 0px 0px 5px !important;
  height:fit-content;
  padding-left: 1px solid #eaeaef;
} */
.user-selection .MuiFormControl-root {
  width: 69% !important;
}

.react-flow__edge-textwrapper {
  z-index: 1000 !important;
}

.user-list > div:first-of-type {
  border: 1px solid #c4c4c4 !important;
  border-radius: 4px !important;
  padding: 8px !important;
}

#main-content {
  padding: 0px 0px 0px 5px !important;
  border-left: 1px solid #eaeaef !important;
}

.taskclasscust {
  overflow-x: scroll;
  overflow-y: scroll;
}

.MuiTableRow-hover:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.text-area {
  border: 1px solid rgb(170, 170, 170);
  border-radius: 5px;
  padding: 10px;
}

@media (max-width: 768px) {
  .taskclasscust {
    width: 100vw;
    overflow-x: scroll;
  }
  .zorp-card {
    border-radius: 6px;
    width: 100%;
  }
}

.taskclasscust .MuiToolbar-gutters:nth-child(0) {
  display: flex !important;
  flex-direction: column-reverse;
  justify-content: flex-end;
}

.taskclasscust div .MuiToolbar-gutters {
  display: flex !important;
  width: 100%;
  justify-content: flex-end;
}

.filterholdercust {
  width: 100%;
  text-align: right;
}

@media (min-width: 768px) {
  .zorp-card {
    border-radius: 6px;
    padding-top: 2%;
    padding-bottom: 2%;
    box-shadow: rgba(0, 0, 0, 0.145) 0px 3px 8px;
    width: 100%;
  }
  .timeSlotParentDiv {
    width: 100vw !important;
  }
  .col-md-4 {
    text-align: left !important;
    width: 33.33%;
    padding-right: 2% !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .col-md-8 {
    width: 66%;
  }

  .mdDisplayClass {
    width: 50% !important;
  }
  .custom-lf {
    margin-left: 1.5%;
  }
  .custom-lf-2 {
    margin-left: inherit;
  }
  .custom-lf-3 {
    margin-left: 2.6%;
  }
  .oid-wd {
    width: 200%;
  }
  .text-md-center {
    text-align: center;
  }
  .md-Flex {
    display: flex !important;
    flex-direction: column !important;
  }
  .custom-date-picker input {
    border: solid 1px #d4d4d4;
    padding: 10px;
    width: 92% !important;
    margin-left: -12px;
    border-radius: 4px;
    margin-bottom: 1%;
    background-color: transparent;
  }
  .col-md-4-new {
    text-align: left !important;
    width: 25.33%;
    padding-right: 2% !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .col-md-8-new {
    width: 61%;
  }
}
@media (min-width: 1100px) {
  .col-md-4-new {
    text-align: left !important;
    width: 15.33%;
    padding-right: 2% !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .col-md-8-new {
    width: 71%;
  }
}

@media (min-width: 768px) {
  .bt-row {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .bt-col-md-6 {
    width: 50%;
    margin: 0;
  }
  .bt-col-md-8 {
    width: 50%;
  }
  .bt-col-md-4 {
    width: 40%;
    display: flex;
    align-items: center;
    text-align: left;
  }
}
@media (max-width: 767px) {
  .bt-row {
    display: block;
    width: 100%;
  }
  .bt-col-md-6 {
    width: 100%;
    margin: 0;
  }
  .bt-col-md-8 {
    width: auto;
  }
  .bt-col-md-4 {
    width: 100%;
    text-align: left;
  }
}

.form-control {
  width: 100% !important;
  padding-top: 10.5px !important;
  padding-bottom: 10.5px !important;
}

#task-map.leaflet-container {
  width: 100% !important;
  height: 600px !important;
  border: 0.5px solid #000;
}

.cstm-select div:nth-child(1) {
  padding-top: 9px;
}

#demo-controlled-open-select {
  border: solid 1px #d4d4d4;
  border-radius: 4px;
  padding: 10px;
}
@media (max-width: 768px) {
  .col-md-4 {
    text-align: left !important;
    width: 100%;
  }
  .col-md-8 {
    width: auto;
  }
  .col-md-4-new {
    text-align: left !important;
    width: 100%;
  }
  .col-md-8-new {
    width: auto;
  }
  .custom-lf {
    margin-left: 3%;
  }
  .custom-lf-2 {
    margin-left: 3%;
  }
  .custom-lf-3 {
    margin-left: 3%;
  }
  .custom-date-picker input {
    border: solid 1px #d4d4d4;
    padding: 4% 5%;
    border-radius: 4px;
    margin-bottom: 4%;
    background-color: transparent;
  }
}

.react-flow__handle-left {
  left: 1px !important;
}

.react-flow {
  background: #e5e5e5;
}

.react-flow__handle-right {
  right: 1px !important;
}

.text-left {
  text-align: left !important;
}
.inc-t0 input {
  background-color: #fff0;
  padding: 8px 8px !important;
}
#root {
  overflow-x: hidden;
  overflow-y: hidden;
}
* {
  font-family: "Inter", Times, serif;
}

/* .MuiToolbar-root .MuiButton-root {
  padding: 6px 12px !important;
} */
.showTaskParentDiv .MuiCard-root {
  height: 90vh;
  overflow: auto;
}

.zorpComment .MuiInputBase-root {
  padding: 4px;
}

.disableTextField {
  color: #6f6f6f !important;
}

.showTaskParentDiv .MuiButton-contained {
  padding: 4px 12px !important;
}

.zorpComment .MuiInputBase-root {
  padding: 4px;
}

.disableTextField {
  color: #6f6f6f !important;
}

.showTaskParentDiv .MuiButton-contained {
  padding: 4px 12px !important;
}

.showTaskParentDiv .contentWrapper {
  background: #fff !important;
}

.showTaskParentDiv {
  background: #fff !important;
  height: 100vh;
  overflow: hidden;
}

hr {
  margin: 0;
  padding: 0;
  margin-left: -2%;
}

.rbc-calendar {
  /* width: 617px; */
  overflow-x: scroll;
  width: 95vw;
}

/* @media(max-width:597px){
    .scheduleCalenderClass .rbc-calendar{
        width: calc(100vw - 0px ) !important;
    }
    .scheduleCalenderClass1 .rbc-calendar{
        width: calc(100vw - 0px ) !important;
    }
}

@media(min-width:598px){
    .scheduleCalenderClass .rbc-calendar{
        width: calc(100vw - 240px ) !important;
    }
    .scheduleCalenderClass1 .rbc-calendar{
        width: calc(100vw - 55px ) !important;
    }
} */

/* .MuiTableCell-sizeSmall {
  padding-top: 10px !important;
  padding-right: 2px !important;
  padding-bottom: 10px !important;
} */

.rbc-event-label {
  font-size: 100% !important;
}

.splitter * {
  box-shadow: none !important;
}

.RaAppBar-menuButton-15 {
  margin-left: 0.64em !important;
}
span.MuiButtonBase-root.MuiTableSortLabel-root.MuiTableSortLabel-active.RaDatagridHeaderCell-active-81 {
  color: white;
}

span.MuiButtonBase-root.MuiTableSortLabel-root.MuiTableSortLabel-active.RaDatagridHeaderCell-active-81:hover {
  color: white;
}
span.MuiButtonBase-©root.MuiTableSortLabel-root:hover {
  color: white;
}
div#demo-controlled-open-select {
  padding: 20px;
}
div.MuiInput-underline:before {
  border-bottom: 0;
  content: "";
  transition: none;
  position: initial;
}
div#demo-controlled-open-select {
  border: solid 1px rgb(148, 148, 148);
}
div.MuiInput-underline:after {
  border-bottom: 0;
  content: "";
  transition: none;
}

.react-flow__edge-textbg:hover {
  cursor: pointer;
}

/* svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  cursor: pointer;
  background-color: #fff;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  color: #98a2b3 !important;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  height: 0.8em;
  margin-left: 3px;
  margin-right: 10px;
} */

primary {
  color: #efb1aa;
}

secondary {
  color: #231e1d;
}
main.RaLayout-contentWithSidebar-3 {
  background-color: black;
}

.RaLayout-content-4 {
  padding-top: 0px !important;
  padding-right: 0px !important;
}

.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.preview-styles {
  margin-top: 0px !important;
}

.loginInner {
  max-width: 350px;
  text-align: center;
}

.loginImg {
  max-width: 180px;
  margin: 0 auto 20px;
}

.loginTitle {
  font-size: 1.6rem;
  font-weight: bold;
  margin: 2rem;
  color: rgba(0, 0, 0, 0.8);
}

.loginForm {
  text-align: left;
  max-width: 100%;
}

.copyright {
  font-size: 0.8rem;
  opacity: 0.6;
}

.email {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.emailInner {
  max-width: 350px;
  text-align: center;
}

.logogImg {
  max-width: 180px;
  margin: 0 auto 20px;
}

.emailTitle {
  font-size: 1.6rem;
  font-weight: bold;
  margin: 3.4rem;
}

.signup {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.signupInner {
  max-width: 350px;
  text-align: center;
}

.signupImg {
  max-width: 180px;
  margin: 0 auto 20px;
}

.signupTitle {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 1.4rem !important;
}

.signupForm {
  text-align: left;
  max-width: 100%;
}

.copyright {
  font-size: 0.8rem;
  opacity: 0.6;
  margin: 1rem;
}

.signup-divider::before {
  border-top: 1px solid !important;
}

.signup-divider::after {
  border-top: 1px solid !important;
}

.signup-divider {
  font-size: 0.8rem;
  opacity: 0.6;
}

.script-editor-divider::before {
  border-top: 1px solid !important;
  margin-top: -15px;
}

.script-editor-divider::after {
  border-top: 1px solid !important;
  margin-top: -15px;
}

.script-editor-divider {
  font-size: 0.8rem;
  opacity: 0.6;
}

.setupAccount {
  display: flex;
  height: 100vh;
}

.setupAccountLeft {
  width: 50%;
  background-color: #3e66fb;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.setupAccountRight {
  width: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.mainImage {
  width: 90%;
  max-width: 450px;
  margin: 0 auto;
}

.logoImgSA {
  width: 160px;
  margin: 0 auto 30px;
}

.setupAccountImage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.googleLoginButton {
  background-color: transparent !important;
  padding: 0 !important;
  width: 100%;
  display: flex !important;
  /* grid-template-columns: 12% 88%; */
  align-items: center !important;
  border: 1px solid #4284f3 !important;
  box-shadow: null !important;
  border-radius: 0px !important;
  font-family: "Roboto", sans-serif !important;
}

.googleLoginButtonLogin {
  background-color: transparent !important;
  padding: 0 !important;
  width: 100%;
  display: grid !important;
  grid-template-columns: 12% 88%;
  align-items: center !important;
  border: 1px solid #4284f3 !important;
  box-shadow: null !important;
  border-radius: 0px !important;
}

.googleLoginButtonLogin > span {
  width: 100% !important;
  color: white;
  background: #4284f3;
  font-family: "Roboto", sans-serif !important;
}

.googleLoginButton > span {
  width: calc(100% - 33px) !important;
  color: white;
  background: #4284f3;
  font-family: "Roboto", sans-serif !important;
}

.react-tel-input .flag-dropdown {
  background-color: #fff;
  border-right: none !important;
}

.setupAccountRight .loginForm {
  width: 350px;
  max-width: 350px;
}

@media screen and (max-width: 720px) {
  .mainImage {
    display: none;
  }

  .logoImgSA {
    display: none;
  }
}

@media screen and (max-height: 700px) {
  .mainImage {
    max-width: 300px;

    /* display: none; */
  }

  .setupAccountRight {
    height: 100%;
    overflow-y: scroll;
    padding-top: 200px;
    padding-bottom: 20px;
  }
}

@media screen and (max-width: 767px) {
  .setupAccount {
    height: auto;
    flex-direction: column;
  }
  .setupAccountLeft,
  .setupAccountRight {
    width: 100%;
  }

  .setupAccountRight .loginForm {
    padding: 0 50px;
    width: 100%;
    max-width: 100%;
  }
  .setupAccountRight {
    height: auto;
    overflow-y: inherit;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.content__requestInputColumn {
  display: flex;
  align-items: center;
}

.rta {
  position: relative;
}

.rta__autocomplete {
  position: absolute;
  display: block;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  left: 0 !important;
  top: 40px !important;
  box-shadow: 0 10px 20px #000;
  height: 150px;
  overflow-y: scroll;
  z-index: 9;
}

.rta__autocomplete::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px #c2c9d1;
  background-color: #f5f5f5;
}

/* display: none for scrollbar */
::-webkit-scrollbar {
  display: none !important;
}

.rta__autocomplete::-webkit-scrollbar {
  width: 0;
  background-color: #f5f5f5;
}

.rta__autocomplete::-webkit-scrollbar-thumb {
  background-color: #c2c9d1;
}

.rta__item {
  padding: 5px 0;
  font-size: 12px;
  color: #000;
  font-weight: 400;
}

.update__DataField .rta {
  width: 100%;
}

.update__DataField {
  max-height: 75% !important;
  overflow: auto;
  margin-top: 8px;
}

.update__DataField__paramsTableRow,
.update__DataField__headerTableRow {
  display: flex;
  border: 1px solid #ccc;
}

.update__DataField__headerTableRow {
  background-color: #f0f0f3;
  font-weight: 600;
  font-size: 14px;
}

.update__DataField__paramsTableColumn_left {
  border-right: 1px solid #ccc;
  overflow: auto;
  font-size: 12px;
  padding: 10px;
  width: 50%;
}

.update__DataField__paramsTableColumn_left:first-child {
  overflow: auto;
}

.update__DataField__paramsTableColumn_left:last-child {
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-right: 0;
}

.update__DataField__paramsTableColumn_right {
  border-right: 1px solid #ccc;
  overflow: auto;
  font-size: 12px;
  padding: 10px;
  width: 50%;
}

.update__DataField__paramsTableColumn_right:first-child {
  overflow: auto;
}

.update__DataField__paramsTableColumn_right:last-child {
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-right: 0;
}

.update__DataField__paramsTableIcons {
  font-size: 18px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

. {
  height: 32rem;
  overflow-y: auto;
}

.disabledImg {
  cursor: not-allowed !important;
}

.pointer {
  cursor: pointer !important;
}

.linkTitle {
  color: rgb(37 99 235) !important;
}

.redoc-wrap > .menu-content {
  position: fixed !important;
  top: 6vh !important;
}

.redoc-wrap > .api-content {
  margin-left: 260px !important;
}

.redoc-wrap > .menu-content > .scrollbar-container {
  height: 73vh !important;
  overflow-y: auto I !important;
}
.Mui-selected {
  color: black !important;
}
.doc-left-margin {
  margin-left: 300px;
}

@media (max-width: 800px) {
  .redoc-wrap > .api-content {
    margin-left: 0px !important;
  }

  .doc-left-margin {
    margin-left: 40px;
  }
}
/* .MuiTabs-root {
  border-bottom: 2px solid #e5e5e5;
} */

.jsoneditor {
  height: 49vh !important;
}

.dropdown__control {
  /* padding: 5px !important; */
  border: 2px solid #eaeaef;
}
.MuiTooltip-arrow {
  color: #3e66fb !important;
}

/* .MuiChip-root {
  padding: 14px 4px !important;
  background-color: #e1e4e8 !important;
  color: #333333 !important;
  font-size: 14px !important;
} */

.MuiOutlinedInput-input {
  padding: 6px 8px !important;
  font-size: 14px !important;
}

.list_of_text_add_button {
  padding: 0px !important;
}

.role__acl > .MuiFormControlLabel-labelPlacementStart {
  margin: 0px !important;
}

/* .MuiFormControlLabel-labelPlacementStart {
  margin: 0px !important;
} */

.role__acl > .MuiFormControlLabel-labelPlacementStart > span {
  font-size: 14px !important;
}

.MuiTypography-body2 {
  font-weight: 400 !important;
  font-size: 14px !important;
}

.MuiTableCell-sizeSmall:last-child {
  padding-right: 0px !important;
}

.RaLayout-appFrame {
  margin-top: 0px !important;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: #f0f2f3 !important;
}

input[type="checkbox"]:indeterminate::before {
  content: "";
  width: 8px;
  height: 2px;
  display: flex;
  align-items: center;
  background-color: #0175ff;
  margin-top: 5.4px;
  margin-left: 2.5px;
}

input[type="checkbox"]:indeterminate::after {
  content: "";
  width: 10px;
  display: flex;
  align-items: center;
  height: 10px;
  border-width: 0px 0 0 0;
  position: absolute;
  top: 9px;
  left: 5px;
  margin-top: 10px;
}

.makeStyles-mentionsInput-58__input {
  width: 100% !important;
}

/* remove counter in the textfield with type number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.filter-hover:hover {
  background-color: #ddebff;
}

.filter-list-datafields::-webkit-scrollbar {
  width: 6px;
}

.filter-list-datafields {
  scrollbar-width: thin;
  scrollbar-color: var(--filterThumbBG) var(--filterScrollbarBG);
}

.filter-list-datafields::-webkit-scrollbar-track {
  background: var(--filterScrollbarBG);
}

.filter-list-datafields::-webkit-scrollbar-thumb {
  background-color: var(--filterThumbBG);
  border-radius: 6px;
  border: 3px solid var(--filterScrollbarBG);
}

.MuiMenu-list {
  flex-direction: column !important;
  display: flex !important;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 8px;
}

/* .MuiDrawer-root

 {
  
display
: none !important;
} */

/* bi report embedding iframe */
div.bi-iframe-container iframe {
  width: 100%;
  height: 100%;
}

.ag-header-cell {
  width: 200px;
  left: 0px;
  color: #475467;
  font-weight: 500;
  font-size: 12px;
  border-right: 1px solid #eaecf0;
}

.ag-header-viewport {
  background: #f9fafb;
}

.ag-cell {
  font-weight: 400;
  font-size: 14px;
  color: #101828;
  border: 1px solid #eaecf0;
  padding: 0px !important;
}

.ag-row {
  border: 0px !important;
  background: #fff !important;
}

.ag-paging-panel {
  justify-content: center !important;
}

.customHeaderMenuButton {
  float: left;
  margin: 0 0 0 3px;
}

.customHeaderLabel {
  float: left;
  margin: 0 0 0 3px;
}

.customSortDownLabel {
  float: left;
  margin: 0 0 0 3px;
}

.customSortUpLabel {
  float: left;
  margin: 0;
}

.customSortRemoveLabel {
  float: left;
  margin: 0 0 0 3px;
  font-size: 11px;
}

.active {
  color: cornflowerblue;
}

.ag-cell-wrapper {
  padding: 0px 8px !important;
}

.MuiMenuItem-root {
  font-size: 14px !important;
  font-weight: 500 !important;
  /* color: #344054 !important; */
}

.zLayout-open .MuiDrawer-root {
  width: 250px !important;
}

.zLayout-open .MuiDrawer-root .MuiDrawer-paper {
  border: none !important;
}

.zLayout-close .MuiDrawer-root {
  width: 80px !important;
}

.RaSidebar-fixed {
  height: calc(100vh - 0.1em) !important;
}

.layout .MuiDrawer-paperAnchorLeft > div {
  height: calc(100vh - 0.1em) !important;
}

.showPageTabs .MuiTabs-flexContainer {
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

a[href="https://retool.com?utm_source=retool&utm_medium=public-app&utm_content=4a8d0297-f5ba-4de3-adcb-a1a02e4119be"]
{
  display: none !important;
}

.retool-hide {
  width: 100%;
  background: rgba(255, 255, 255, 1);
  height: 100px;
  /* z-index: var(--floating-presentation-nav-z-index); */
  /* left: 80px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* gap: 8px; */
  position: absolute;
  font-size: 16px;
  font-weight: 600;
  bottom: 0px;
}

.MuiPickersDay-root {
  font-size: 14px !important;
}

.slide-in {
  transform: translateX(100%);
  transition: transform 2s ease-out;
}

.slide-in.visible {
  transform: translateX(0);
}


.monitor-content {
  transition: max-height 0.5s ease-in-out; /* Adjust time as needed */
}

.closed {
  height: 0px;
}

.lineHighlight {
  background-color: rgba(255, 215, 0, 0.3);
}
//All colors & font
$primary_redDark_1: #781C1C;
$primary_redDark_2: #B4292A;
$primary_redDefault: #F03738;
$primary_redLight_1: #F79B9B;
$primary_redLight_2: #FBCDCD;
$primary_redLightest_1: #FDEBEB;
$primary_redLightest_2: #FEF5F5;

$primary_blueDark_1: #1F337E;
$primary_blueDark_2: #2E4CBC;
$primary_blueDefault: #3E66FB;
$primary_blueLight_1: #9EB3FD;
$primary_blueLight_2: #CFD9FE;
$primary_blueLightest_1: #ECF0FF;
$primary_blueLightest_2: #F5F7FF;

$primary_blackDefault: #333333;
$primary_blackLight_1: #808080;
$primary_blackLight_2: #BFBFBF;
$primary_blackLightest_1: #E5E5E5;
$primary_blackLightest_2: #F2F2F2;

$primary_grayDark_1: #616569;
$primary_grayDark_2: #92979D;
$primary_grayDefault: #C2C9D1;
$primary_grayLight: #E1E4E8;
$primary_grayLightest_1: #F0F2F3;
$primary_grayLightest_2: #F9FAFA;

$warning-color: #F3BB1C;
$success-color: #3CC13B;

$primary_font: 'Inter', sans-serif;
.zorpState {
  border-color: #0041d0;
  border-style: double;
}

.terminalState {
  border-color: #d0005a;
}

.react__flowWrapper {
  height: 86vh;
  margin-left: -1px;
}

.action__mainForm {
  /* border-left: 2px solid #eaeafe; */
  /* border-top:2px solid #eaeafe; */
}

.canvas {
  flex-direction: column;
  display: flex;
  /* bottom: 30%; */
  height: 100%;
}

.canvas aside {
  border-right: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  background: #fcfcfc;
}

.canvas aside > * {
  margin-bottom: 10px;
  cursor: grab;
}

.canvas aside .description {
  margin-bottom: 10px;
}

.canvas .state {
  margin-bottom: 10px;
  border-color: #0041d0;
  border-width: 10;
}

.canvas .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
  position: relative;
}

.isDisabled {
  pointer-events: none;
}

.button {
  padding: 6px 11px 6px 11px;
  border-radius: 4px;
  color: white;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  /* text-transform: uppercase; */
}

.button.primary:disabled {
  background-color: #a4c6eb;
  cursor: no-drop;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: black !important;
  /* opacity: 80% !important; */
}

.header {
  border-top: 1px solid #eaeaef;
  border-bottom: 1px solid #eaeaef;
}

.action__dragDiv {
  margin-top: 1rem;
  /* height:18rem; */
  /* overflow-y: auto; */
}

.add_new_button {
  float: right;
  color: #3e66fb;
  font-weight: 600;
  font-size: 12px;
}

.description_input {
  padding: 0.5rem;
  width: 100%;
  border-radius: 0.5rem;
  border: 1px solid grey;
}

.description_input_div {
  padding-top: 0.5rem;
}

.eventName_input_field {
  padding-top: 1rem;
  font-size: 0.5rem;
}

.controlCenter_sidebar_subdiv {
  padding: 1rem;
}

.add_new_button:hover {
  cursor: pointer;
}

.controlCenter_sidebar {
  width: 95%;
  /* border-left: 1px solid grey;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey; */
  /* border-left: 1px solid grey; */
}

.button_container {
  /* margin-right: 1rem; */
  display: flex;
  align-items: center;
  font-family: 'Inter', sans-serif;
  margin-top: 6px;
}

.primary {
  background-color: #007aff;
}

.secondary {
  background-color: #292929;
  /* opacity: 0.8; */
}

@media screen and (min-width: 768px) {
  .canvas {
    flex-direction: row;
  }

  .canvas aside {
    width: 20%;
    max-width: 180px;
  }
}

.login {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &Inner {
    max-width: 350px;
    text-align: center;
  }

  &Img {
    max-width: 180px;
    margin: 0 auto 20px;
  }

  &Title {
    font-size: 1.6rem;
    font-weight: bold;
    margin: 2rem;
    color: rgba(0, 0, 0, 0.8);
  }

  &Form {
    text-align: left;
    max-width: 100%;
  }

  label {
    margin-bottom: 1rem;
  }

  input {
    font-size: 1.4rem;
    padding: 1rem 1.6rem;
  }

  .googleLogin {
    background-color: #000 !important;
    color: #fff !important;
    font-weight: 600 !important;
    width: 100% !important;
    justify-content: center;
    padding: 0.5rem !important;
    &:hover {
      opacity: 0.8 !important;
    }
  }
}

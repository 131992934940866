.MuiMenuItem-root {
  font-size: 14px !important;
  font-weight: 500 !important;
  /* color: #344054 !important; */
}

.zorpTable .MuiTableCell-root {
  /* width: 200px; */
  border-right: 1px solid #eaecf0 !important;
  box-shadow: none !important;
  padding: 0px 4px !important;
  color: #101828 !important;
  font-weight: 400 !important;
  font-size: 11px !important;
}

.zorpTable .MuiToolbar-root {
  overflow: visible !important;
  padding: 8px 16px !important;
  z-index: 2 !important;
}

.zorpTableComponent .zorpTableTopToolbar {
  display: none !important;
}

.zorpTableTopToolbar .MuiButtonBase-root {
  padding: 4px 8px !important;
  svg {
    font-size: 16px !important;
  }
}

.zorpTableTopToolbar .MuiButtonBase-root[aria-label="Show/Hide columns"] svg {
  font-size: 12px !important;
}

#muitoptoolbar {
  padding: 8px 0px !important;
}

.zorpTable .MuiTableCell-body {
  min-height: 30px !important;
  background: none;
  z-index: 0;
  padding: 0 !important;
  background-color: white;
}

.zorpTable .MuiBox-root .MuiCollapse-root {
  width: 100% !important;
  display: none;
}

.Mui-TableHeadCell-ResizeHandle-Wrapper {  
  margin-right: -8px !important;
  height: 100% !important;
}

.Mui-TableHeadCell-ResizeHandle-Wrapper:hover {
  background: #3054B9;
  cursor: col-resize;
}

.zorpTable .MuiBox-root .MuiCollapse-wrapperInner {
  width: 100% !important;
}

.zorpTable .MuiBox-root {
  padding: 0px !important;
  padding-right: 4px !important;
  font-weight: 500 !important;
  color: #1D2939 !important;
}

.zorpTable-addRow {
  margin-top: 5px;
}

/* select component with aria-label="Toggle select row" */
.zorpTable .MuiTableCell-body .MuiCheckbox-root {
  padding: 0;
  margin: 0;    
  margin-left: 0 !important;
  width: 100%;
  background: white;
  border-radius: 0;
  z-index: 4;
}

.zorpTable .MuiTableHead-root {
  z-index: 1 !important;
}

.MuiTableRow-root {
  box-shadow: none !important;
}

.MuiTableContainer-root {
  height: calc(100% - 111px) !important
}

.zorpTable .MuiBox-root {
  padding: 0px 2px !important;
  font-weight: 500 !important;
  color: #1D2939 !important;
}

.my-menu {
  z-index: 99999 !important;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  width: 200px;
  /* box-shadow: none; */
}

.menuItem-copy {
  border-bottom: 1px solid #eaecf0;
  z-index: 99999;
  padding: 0px;
  padding: 4px 8px !important;
  display: flex;
  justify-content: space-between;
}

.MuiList-root .MuiButtonBase-root {
  justify-content: start !important;
  padding: 4px 8px;
}
.menuItem-s {
  z-index: 99999;
  padding: 0px;
  padding: 4px 8px !important;
}

.menuItem-s:hover {
  background-color: #f5f8ff;
}

.menuItem-delete {
  z-index: 99999;
  padding: 0px;
  padding: 4px 8px !important;
  border-top: 1px solid #eaecf0;
}

.Mui-TableHeadCell-Content {
  width: 200px;
  left: 0px;
  color: #475467;
  font-weight: 500;
  font-size: 12px;
  /* border-right: 1px solid #eaecf0; */
}

.zorpTable .MuiTableCell-head {
  padding: 0px 4px !important;
  border-top: 1px solid #eaecf0 !important;

  svg {
    width: 12px !important;
    height: 12px !important;
  }
}

.zorpTable .addColumnIcon {
  position: relative;
  z-index: 1;
  background-color: white;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.zorpTable .addColumnIcon:hover {
  background-color: #f0f0f0;
}

.zorpTable .Mui-TableHeadCell-Content-Labels {
  width: 95% !important;
}
.Mui-TableHeadCell-Content-Wrapper {
  width: 95% !important;
}

.zorpTable .MuiMenuItem-root {
  justify-content: flex-start !important;
  padding: 4px 8px !important;
}

.ZorpTablePaginationParent-root {
  right: auto !important;
}

.MuiPaper-root .MuiList-padding .MuiButtonBase-root .MuiBox-root .MuiBox-root {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
} 

.MuiPaper-root .MuiList-padding .MuiBox-root .MuiButtonBase-root {
  color: var(--primary) !important;
  text-transform: none !important;
}

.MuiPaper-root .MuiList-padding .MuiBox-root .Mui-disabled {
  color: #c4c4c4 !important;
}

.MuiPaper-root .MuiList-root .MuiMenuItem-root .MuiBox-root .MuiFormControlLabel-root .MuiSwitch-root  .MuiButtonBase-root {
  padding: 9px !important;
}

.MuiSwitch-track {
  background-color: var(--secondary) !important;
}

.MuiPaper-root .MuiList-root .MuiMenuItem-root .MuiBox-root .MuiBox-root .MuiIconButton-sizeSmall svg {
  width: 18px !important;
  height: 18px !important;
}

.zorpTable .MuiCheckbox-root {
  
}

.MuiPaper-root .MuiList-root .MuiMenuItem-root .MuiBox-root > button:nth-of-type(2) {
  display: none !important;
}

.MuiPaper-root .MuiList-root .MuiMenuItem-root .MuiBox-root  .MuiFormControlLabel-root .MuiTypography-root {
  color: black !important;
}

.MuiSwitch-track {
  background-color: #101828  !important;
  width: 50px !important;
}
.grid-item {
  border-radius: 4px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: white;
  padding: 10px;
  overflow: hidden;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.grid-wrapper-item{
  contain: strict;
  height: 100%;
  overflow: auto;
}
.grid-item__title {
  font-size: 14px;
  color: black;
  padding: 4px 0;
  text-align: center;
}
.grid-item__title:hover {
  cursor: move;
}
.grid-item__title .panel {
  box-shadow: none;
  border: none;
}
.grid-item__title .panel:focus {
  outline: none;
}
.grid-item__title .panel-heading {
  padding: 3px 0;
  background: #171819;
  border: none;
  color: rgba(255, 255, 255, 0.7);
}
.grid-item__title .panel-body {
  padding: 0 0 0 15px;
  background: #171819;
  color: rgba(255, 255, 255, 0.7);
}

.grid-item__graph {
  flex: 1;
}

.type-item {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  padding: 3px 0;
}
.type-item:hover {
  color: #fff;
}

.type-item.selected {
  color: #fff;
}

.data-edit__error {
  color: rgba(255, 0, 0, 0.7);
  font-size: 12px;
  padding: 3px 0;
}

.recharts-text {
  font-size: 12px;
}

.detail__info {
  color: #fff;
  padding: 20px;
}

.detail__edit {
  padding: 20px;
}

.detail__info > div {
  padding: 5px 0;
}

label {
  display: block;
}
.dropdown > .btn-default,
.dropdown.open > .btn-default {
  background: #171819;
  border: 1px solid #141414;
  color: #fff;
}
.dropdown > .btn-default:hover,
.dropdown > .btn-default:focus .dropdown.open > .btn-default:hover,
.dropdown.open > .btn-default:focus {
  background: #171819;
  border: 1px solid #141414;
  color: #fff;
  outline: none;
}

.dropdown-menu {
  border: 1px solid #141414;
  background: #171819;
  color: #fff;
}
.dropdown-menu > li > a {
  color: rgba(255, 255, 255, 0.7);
}
.dropdown-menu > li > a:hover {
  background: #171819;
  color: #fff;
}

.react-resizable {
  max-height: 80vh !important;
  overflow: auto !important;
}

.react-grid-item > .react-resizable-handle::after {
  border-right-color: rgba(255, 255, 255, 0.5);
  border-bottom-color: rgba(255, 255, 255, 0.5);
}

.react-grid-layout {
  max-height: 80vh;
  overflow: hidden;
}


.modal-body {
  padding: 0;
}

.data-edit {
  position: relative;
}
.data-edit__update {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 10;
}

.detail-page__title,
.edit-page__title {
  position: fixed;
  top: 20px;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
}

.go-back {
  position: fixed;
  right: 20px;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  top: 20px;
  z-index: 10;
}

.go-back:hover {
  color: #fff;
  cursor: pointer;
}
